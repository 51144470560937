import React, { useState } from "react";
import { Box, Image, Text, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import mapa from "../../assets/images/mapa-rori.jpg";
import rori from "../../assets/images/rori.svg";
import bgpath from "../../assets/images/bgpath.png";
import arrow from "../../assets/images/arrow.svg";
import homepage from "../../storage/homepage.json";

const Where = ({ language }) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: 56.25em)`);
  const [data, setData] = useState(homepage);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        backgroundColor: "#440086",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: isMobile ? "center" : "flex-start",
          padding: isMobile ? "1rem" : "6rem",
          backgroundImage: isMobile ? "none" : `url(${bgpath})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box
          style={{
            display: "flex",
            whiteSpace: "nowrap",
            justifyContent: "center",
            gap: "0.5rem",
            padding: "1rem",
          }}
        >
          <Text
            style={{
              color: "#ce0085",
              fontSize: isMobile ? "26px" : "36px",
              fontWeight: 700,
            }}
          >
            {data[language].where.title}
          </Text>
        </Box>

        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "center",
            gap: "0.5rem",
            color: "#fff",
            fontSize: isMobile ? "16px" : "26px",
            textAlign: isMobile ? "center" : "left",
            padding: "2rem 0",
            flexWrap: "wrap",
            paddingInline: "1rem",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Text>{data[language].where.belgrade}:</Text>
          </Box>

          {data[language].where.items.map((area, i) => (
            <Box
              key={i}
              style={{
                // display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "0.5rem",
                whiteSpace: "nowrap",
              }}
            >
              {/* <Image src={arrow} alt="arrow" /> */}
              <Text>
                {area} {i < data[language].where.items.length - 1 && ","}
              </Text>
            </Box>
          ))}
        </Box>
        <Text
          style={{
            color: "#fff",
            fontSize: isMobile ? "16px" : "26px",
            padding: "1rem",
            textAlign: isMobile ? "center" : "left",
          }}
        >
          {data[language].where.followUs}
        </Text>
      </Box>
      <Box>
        <Image
          src={mapa}
          alt="mapa"
          style={{
            width: "100%",
          }}
        />
      </Box>
    </Box>
  );
};

export default Where;
